@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

$primary-color: #007dc4;
$primary-color-hover: scale-color($primary-color, $lightness: 32%);

.container {
  background: map-get($backgrounds, gray);
  padding: map-get($section-content, padding);

  .row {
    @include rowSize;
  }

  .header {
    @include section_title;
  }
}

/*==================================
    TIMELINE
==================================*/

/*-- GENERAL STYLES
    ------------------------------*/
.timeline {
  line-height: 1.4em;
  list-style: none;
}

/*----- TIMELINE ITEM -----*/

.timeline-item {
  padding-left: 3rem;
  position: relative;
}

/*----- TIMELINE INFO -----*/

.timeline-info {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}
/*----- TIMELINE MARKER -----*/

.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
  &:before {
    background: $primary-color;
    border: 3px solid transparent;
    border-radius: 100%;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 9px;
    transition:
      background 0.3s ease-in-out,
      border 0.3s ease-in-out;
  }
  &:after {
    content: '';
    width: 3px;
    background: #ccd5db;
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px;
  }
  .timeline-item:last-child &:after {
    content: none;
  }
}

/*----- TIMELINE CONTENT -----*/

.timeline-content {
  padding-bottom: 40px;

  p {
    margin-top: 1rem;
  }

  .responsibilities {
    list-style-type: none;

    li::before {
      content: ' - ';
    }
  }
}

/*----------------------------------------------
        MOD: TIMELINE SPLIT
    ----------------------------------------------*/

.timeline-split {
  @include for_breakpoint(desktop, 'min-width') {
    .timeline {
      display: table;
    }
    .timeline-item {
      display: table-row;
      padding: 0;
    }
    .timeline-info,
    .timeline-marker,
    .timeline-content,
    .timeline-info {
      display: table-cell;
      vertical-align: top;
    }
    .timeline-marker {
      position: relative;
    }
    .timeline-content {
      padding-left: 30px;
    }
    .timeline-info {
      padding-right: 30px;
    }
  }
}
