@import '../../assets/styles/mixins';

$fontColor: #1d1d24;
$animation: 0.5s ease-in-out;
$btn-color: #007dc4;

.btn {
  position: relative;
  display: block;
  color: $fontColor;
  overflow: hidden;
  font-size: 1rem;
  text-decoration: none;
  padding: 1rem 3rem;
  text-transform: uppercase;
  transition: $animation;
  border: 0.125rem solid $btn-color;
  width: fit-content;
  transform-style: preserve-3d;
  cursor: pointer;
  white-space: nowrap;

  @include for_breakpoint(tablet) {
    width: 100%;
    text-align: center;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $btn-color;
    transform: translateX(-100%);
    transition: $animation;
    z-index: -1;
  }

  &:hover {
    color: white;

    &:before {
      transform: translateX(0);
      transition: $animation;
    }
  }
}
