@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.container {
  padding: map-get($section-content, padding);
  @include shadowTopAndBootom;

  .row {
    @include rowSize;
  }

  .header {
    @include section_title;
  }

  .contact-form {
    .error-message {
      background: #fff1f0;
      border: 1px solid #ffeae8;
      color: #db362a;
      padding: 0.75rem;
      margin-bottom: 3rem;
    }

    .success-message {
      background: #ecf8f4;
      border: 1px solid #e3f5ef;
      color: #00874f;
      padding: 0.75rem;
      margin-bottom: 3rem;
    }

    .form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      text-align: right;

      .form-field {
        position: relative;
        margin: 1rem 0;

        &:nth-of-type(n + 3) {
          grid-column-end: span 2;
        }

        .input-text {
          width: 100%;
          height: 2.5rem;
          font-size: 1rem;
          padding: 1.25rem;

          &.error-input {
            border-color: red;
            border-width: 0 0 2px 0;
          }

          &.without-error-input {
            border-width: 0 0 2px 0;
          }

          &:focus {
            outline: none;

            + .label {
              transform: translateY(-1.5rem);
            }
          }
        }

        .error-form-message {
          color: red;
          height: 1rem;
        }

        .label {
          position: absolute;
          left: 1.25rem;
          bottom: 1.5rem;
          color: #5b5b5b;
          cursor: text;
          transition: transform 0.2s ease-in-out;
        }

        .submit-btn {
          background: #000000;
          color: white;
          font-family: 'Raleway';
          text-transform: uppercase;
          letter-spacing: 0.125rem;
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 0.5rem 1rem;
          border: none;
          cursor: pointer;
          width: 6.75rem;
          height: 2.5rem;

          .spinner {
            width: 1.188rem;
            height: 1.188rem;

            &.spinner3 {
              border-top: 0.188rem solid rgba(white, 0.5);
              border-radius: 50%;
              animation: rotation 0.8s linear infinite;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
