@import '../../assets/styles/mixins';

.container {
  padding: map-get($section-content, padding);
  @include shadowTopAndBootom;

  .row {
    @include rowSize;

    .header {
      @include section_title;
    }

    .about-me {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .buttons {
      display: flex;
      gap: 2rem;
      margin-top: 3rem;

      @include for_breakpoint(tablet) {
        flex-direction: column;
      }
    }
  }
}
