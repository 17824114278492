@import '../../assets/styles/mixins.scss';

$height: 100svh;
$background: #f8f9fc;
$fontColor: #1d1d24;
$imageSize: 20rem;
$btn-color: #007dc4;
$animation: 0.5s ease-in-out;

.container {
  height: $height;
  background: $background;
  color: $fontColor;
  padding: 5rem 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  grid-template-columns: $imageSize + 3rem fit-content(100%);

  @include for_breakpoint(tablet) {
    grid-template-columns: 1fr;
    justify-items: center;
    padding: 5%;
  }

  .image {
    border: 0.75rem solid white;
    border-radius: 50%;
    width: $imageSize;
    height: $imageSize;
    object-fit: cover;
    box-shadow: 0 1.25rem 2.5rem rgba(0, 0, 0, 0.1);
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    transition: $animation;

    @include for_breakpoint(desktop) {
      border: calc(0.75rem / 1.5) solid white;
    }

    &:hover {
      filter: grayscale(0);
      -webkit-filter: grayscale(0);
    }
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include for_breakpoint(tablet) {
      align-items: center;
      text-align: center;
    }

    .title {
      font-weight: bold;
      font-size: min(max(2rem, 5vw), 4rem);
    }

    .animation {
      overflow: hidden;
      font-size: 2.25rem;
      text-transform: uppercase;
      height: 5rem;

      .skill:first-child {
        animation: text-animation 8s infinite;
      }

      .skill {
        height: 5rem;
        display: flex;
        align-items: center;

        @include for_breakpoint(mobile) {
          justify-content: center;
        }
      }
    }
  }
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: -5rem;
  }
  20% {
    margin-top: -5rem;
  }
  30% {
    margin-top: -10rem;
  }
  40% {
    margin-top: -10rem;
  }
  50% {
    margin-top: -15rem;
  }
  60% {
    margin-top: -15rem;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}
