$animation: 0.5s ease-in-out;
$sandwichColor: #20232f;
$menuColor: #20232f;
$sizeSandwich: 2rem;

.sandwich-navigation {
  width: $sizeSandwich;
  height: $sizeSandwich;
  top: 2rem;
  right: 3rem;
  position: fixed;
  z-index: 1;

  .input-checkbox {
    display: none;

    &:checked {
      + .menu {
        box-shadow:
          0 0 0 100vw #fff,
          0 0 0 100vh #fff;
        border-radius: 0;
        background: white;

        .hamburger {
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);

          &:after {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            bottom: 0;
          }

          &:before {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            top: 0;
          }
        }

        + nav {
          opacity: 1;
          transition-delay: 350ms;
          visibility: visible;
        }
      }
    }

    &:not(:checked) {
      + .menu {
        + nav {
          visibility: hidden;
        }
      }
    }
  }

  .menu {
    position: absolute;
    width: 2rem;
    height: 2rem;
    transition: $animation;
    cursor: pointer;

    .hamburger {
      position: absolute;
      top: 1rem;
      width: 2rem;
      height: 0.125rem;
      background: $sandwichColor;
      transition: $animation;

      &:after,
      &:before {
        transition: $animation;
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: $sandwichColor;
      }

      &:before {
        top: -0.625rem;
      }

      &:after {
        bottom: -0.625rem;
      }
    }
  }

  nav {
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 0;
    opacity: 0;
    transition: 0.35s ease;
    list-style-type: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    transform: translate(0, -50%);

    li {
      a {
        text-decoration: none;
        transition: 0.3s ease;
        font-size: 1.5rem;

        .activated {
          color: red;
        }

        &:hover {
          color: tomato;
          text-decoration: underline;
        }
      }
    }
  }
}
