@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/variables';

.skills {
  background: map-get($backgrounds, gray);
  padding: map-get($section-content, padding);

  .row {
    max-width: map-get($section-content, max-width);
    margin: 0 auto;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    justify-items: center;
    display: grid;
  }
}
