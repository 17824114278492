$background: #ffffff;
$menuActivated: #007dc4;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

.container {
  background: $background;
}

.menu-activated {
  color: $menuActivated;
}

.footer-top {
  background: transparent url(https://www.adhamdannaway.com/wp-content/themes/dannaway/images/sprite.png) 0 -217px;
  width: 7.375rem;
  height: 5.625rem;
  position: absolute;
  left: 50%;
  margin-left: -3.688rem;
  bottom: -2.5rem;
  cursor: pointer;
}

.notEmpty {
  + label {
    transform: translateY(-1.5rem);
  }
}
