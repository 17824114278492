@import 'variables';

$media: (
  mobile: 640px,
  tablet: 768px,
  desktop: 1024px,
  landscape: 1280px,
);

@mixin for_breakpoint($breakpoint, $screenSize: 'max-width') {
  @each $breakpoints, $size in $media {
    @if $breakpoint == $breakpoints {
      @media screen and ($screenSize: $size) {
        @content;
      }
    }
  }
}

@mixin section_title {
  font-family: 'Raleway';
  font-weight: bold;
  border-bottom: 3px solid #1d1d24;
  font-size: 2rem;
  margin: 0 auto 3rem auto;
  width: max-content;
}

@mixin shadowTopAndBootom {
  box-shadow:
    inset 0px 11px 8px -10px rgba(0, 0, 0, 0.1),
    inset 0px -11px 8px -10px rgba(0, 0, 0, 0.1);
}

@mixin rowSize {
  max-width: map-get($section-content, max-width);
  margin: 0 auto;
}
