@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.footer {
  background: map-get($backgrounds, footer);
  height: 15rem;
  position: relative;
  box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d1d24;

  .header {
    @include section_title;
  }

  .social-networks {
    display: flex;
    gap: 3rem;
    justify-content: center;
  }

  .gradient-white {
    position: absolute;
    width: 100%;
    height: 3rem;
    top: -3rem;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    background-image: -moz-linear-gradient(center bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0, #ffffff),
      color-stop(1, rgba(255, 255, 255, 0))
    );
    background-image: -webkit-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
    background-image: -o-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
    background-image: -ms-linear-gradient(bottom, #ffffff, rgba(255, 255, 255, 0));
    background-image: linear-gradient(rgba(255, 255, 255, 0), #ffffff);
  }
}
